<template>
  <div  :class="{ 'is-active': isActive }"
        class="modal">
    <div  class="modal-background"
          @click="emit('close')"/>
    <div class="modal-card">
      <section  class="modal-card-body is-flex-direction-column">
        <section class="is-full-width mb-4">
          <article class="is-flex is-justify-content-space-between">
            <span class="title-five">
              <slot name="title"/>
            </span>
            <button class="delete"
                    @click="emit('close')"/>
          </article>
        </section>
        <section class="is-full-width">
          <slot name="content"/>
        </section>
      </section>
      <footer class="modal-card-foot">
        <slot name="footer">
          <div class="is-flex is-full-width is-justify-content-flex-end">
            <button class="button"
                    @click="emit('cancel')">
              {{ $t('cancel') }}
            </button>
            <slot name="submitButton">
              <button :disabled="!canSubmit || isLoading"
                    class="button is-success"
                    @click="emit('submit')">
              {{ $t('submit') }}
            </button>
            </slot>
          </div>
        </slot>
      </footer>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  isActive: Boolean,
  canSubmit: {
    type: Boolean,
    default: true
  },
  isLoading: Boolean
})
const emit = defineEmits(['close', 'cancel', 'submit'])
</script>

<style scoped>
.modal-card-body {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
</style>